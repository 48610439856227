import React from "react";
import { Box } from "@mui/system";

const DisplayVersion = () => {
    return (
        <Box sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
            
        }}>
            <h1>Version Number</h1>
            <h1>{process.env.REACT_APP_VERSION}</h1>
        </Box>
    )
}


export default DisplayVersion;