import React, { useRef, useEffect, useContext, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MediaContext } from '../../util/mediaContext';
import { Box } from '@mui/material'
import LinearWithValueLabel from '../ProgressBar/Progressbar.component';
import "../../index.css";
import { setLastSeenVideoTime, getDataFromLocalStorage, updateLocalStorage } from "../../util/common.util";
import { VIDEO_PHOTO_ERROR_MESSAGE } from "../../util";
import { transparent_background_mini } from "../../asset";
import { KeyCode } from '../../util/keycode'


const HTMLVideoPlayer = () => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url');
  const { videoRef,
    setVideoPlaying, 
    VideoPlaying, 
    setshowNavigateButton, 
    selectedVideoIndex, 
    setToggleListerner, 
    toggleLister, 
    setIsError, 
    setErrorMessage,
    deviceId
   } = useContext(MediaContext);
  const navigate = useNavigate();
  const [hideBtns, setHideBtns] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  let timeoutRef  = useRef(null);
  let fullScreenRef = useRef(null);
  let playerDuration = useRef(null);
  let videoPlayingRef = useRef(null);
  let toggleListenerRef = useRef(null);
  let [currentVideo, setCurrentVideo] = useState(JSON.parse(getDataFromLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`)) ? 
  JSON.parse(getDataFromLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`))[selectedVideoIndex]: {})
  
  let listArray = JSON.parse(getDataFromLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`)) ? 
  JSON.parse(getDataFromLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`)) : [];


  useEffect(() => {
    if(!url) {
      setIsError(() => true)
      setErrorMessage(() => localStorage.getItem('Content') === "Video" ? `${VIDEO_PHOTO_ERROR_MESSAGE} Video` : `${VIDEO_PHOTO_ERROR_MESSAGE} Photo`)
    }
    const element = document.getElementById('media-player');
    if (videoRef && videoRef.current) {
      element.onloadedmetadata = function () {
        const duration = this.duration;
        setCurrentVideo(() => {
          return {
            ...currentVideo,
            "playerDuration": duration
          }
        })
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        setshowNavigateButton(false)
        timeoutRef.current = setTimeout(() => {
          setHideBtns(true)
        }, 5000)
      };

      if (!fullScreenRef.current) {
        fullScreenRef.current = document.querySelector('[id^="myvideo"]').requestFullscreen();
      }

      if (currentVideo && currentVideo.lastVisitedTime) {
        videoRef.current.currentTime = currentVideo.lastVisitedTime;
      }
      videoRef.current.play && videoRef.current.play();
      
    }
  }, [])

  useEffect(() => {
    if (currentVideo.playerDuration && (Math.round(currentVideo.playerDuration) !== Math.round(playerDuration.current))) {
      playerDuration.current = currentVideo.playerDuration;
      listArray[selectedVideoIndex] = { ...listArray[selectedVideoIndex], "playerDuration": currentVideo.playerDuration };
      updateLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`, listArray)
    }
  }, [currentVideo.playerDuration])

  const handleChange = (event) => {
      event.preventDefault()
      event.stopPropagation()
    if (event.key === "Enter" || event.keyCode === KeyCode.KEY_ENTER) {
      if (VideoPlaying) {
        setHideBtns(() => false)
        videoRef.current && videoRef.current.pause && videoRef.current.pause()
        setLastSeenVideoTime(localStorage.getItem("Content"), localStorage.getItem("Mode"), selectedVideoIndex, videoRef, "mp4")
        setCurrentVideo(() => {
          return {
            ...JSON.parse(getDataFromLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`))[selectedVideoIndex],
            lastVisitedTime: videoRef.current.currentTime
          }
        })
      } else {
        videoRef.current && videoRef.current.play && videoRef.current.play()
        timeoutRef = setTimeout(() => {
        setHideBtns(true)
      }, 5000)
      }
    } else if (event.keyCode === KeyCode.KEY_BACK ) {
      setVideoPlaying(() => false)
      setShowProgressBar(() => false)
      videoRef.current && videoRef.current.pause && videoRef.current.pause()
      setLastSeenVideoTime(localStorage.getItem("Content"), localStorage.getItem("Mode") ,selectedVideoIndex, videoRef, "mp4")
      navigate(`/wellness-videos/${localStorage.getItem("Mode")}${localStorage.getItem("Mode") === 'Personal' ? `?deviceuuid=${deviceId}` : ''}`)
    } else {
      setToggleListerner(() => !toggleLister)
    }
    document.removeEventListener('keydown', handleChange)
  }

  useEffect(() => {
    if((VideoPlaying !== videoPlayingRef.current) || (toggleLister !== toggleListenerRef.current))
    {
      document.addEventListener('keydown', handleChange, false);
     
      videoPlayingRef.current = VideoPlaying;
      toggleListenerRef.current = toggleLister;
    }
  }, [VideoPlaying, toggleLister])


  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', handleChange)
      document.removeEventListener('ended', handleChange)
      document.removeEventListener('playing', handleChange)
      document.removeEventListener('pause', handleChange)
    }
  }, [])

  const element = document.getElementById('media-player');
  element && element.addEventListener('ended', () => {
    setLastSeenVideoTime(localStorage.getItem("Content"), localStorage.getItem("Mode") ,selectedVideoIndex, "", 'YouTube', 'ended')
    videoRef.current.currentTime = 0;
    videoRef.current.pause();
    videoPlayingRef(() => false);
  }, false);

  element && element.addEventListener('playing', () => {
    setVideoPlaying(() => true)
    setShowProgressBar(() => true)
  }, false);

  element && element.addEventListener('pause', () => {
    setVideoPlaying(() => false)
  }, false);

  if(element) {
    element.onerror = () => {
      setIsError(() => true)
      setErrorMessage(() => localStorage.getItem('Content') === "Video" ? `${VIDEO_PHOTO_ERROR_MESSAGE} Video` : `${VIDEO_PHOTO_ERROR_MESSAGE} Photo`)
    };
  }


  return (
    <div id="myvideo">
      <video ref={videoRef} id="media-player" style={{ height: '100%' }} poster={transparent_background_mini}>
        <source src={url} type="video/mp4"></source>
        Your browser does not support the video tag.
      </video>
      {!hideBtns && currentVideo.playerDuration && showProgressBar && <>
        <Box sx={{
          position: 'absolute',
          width: '100%',
          bottom: '10%'
        }}>
          <LinearWithValueLabel MAX={currentVideo.playerDuration ? currentVideo.playerDuration : 0} value={currentVideo.lastVisitedTime ? currentVideo.lastVisitedTime : 0} />
        </Box>
      </>}
    </div>

  )

}

export default HTMLVideoPlayer;