import React, { useEffect, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../index.css'
import ShowTimer from './ShowTimer'
import CSDate from './csDate'
import { MediaContext } from '../../util/mediaContext'
import { Box } from '@mui/material'
import EmblaCarousel from '../EmblaCarousel/EmblaCarousel'
import { useQuery, VIDEO_PHOTO_ERROR_MESSAGE } from '../../util'
import { Footer, Header, Layout, SVG } from '../common-templates'
import {
  getYoutubeId,
  updateLocalStorage,
  getDataFromLocalStorage,
  getCurrentSelectedIndex,
  handleImageError,
  getDataList
} from '../../util/common.util'
import useEmblaCarousel from 'embla-carousel-react'
import ClassNames from 'embla-carousel-class-names'
import '../styles/embla.css'
import '../styles/sandbox.css'
import { getMediaData } from '../ThumbnailGridView/media-details.api'
import { PhotoAppIcon, VideoAppIcon } from '../../asset'
import { KeyCode } from '../../util/keycode'

const ThumbnailListView = () => {
  let {
    selectedVideoIndex,
    setSelectedVideoIndex,
    setshowNavigateButton,
    content,
    mode,
    setToggleListerner,
    toggleLister,
    videoList,
    setVideoList,
    setContent,
    setMode,
    slideCount,
    setIsError,
    setSlideCount,
    setErrorMessage,
    setDeviceId,
    setPhotoUrlList,
    photoUrlList,
  } = useContext(MediaContext)
  const query = useQuery()

  const SLIDES = Array.from(Array(slideCount).keys())

  const getData = async (_content, _mode) => {
    try {
      let responseData = await getMediaData(
        query.get('deviceuuid'),
        _content,
        _mode,
        setIsError,
        setErrorMessage
      )
      setDeviceId(() => query.get('deviceuuid'))
      setVideoList(() => responseData)
      if (slideCount > responseData.length) {
        setSlideCount(() => responseData.length)
      }
      if (
        _content === 'Photo' ||
        localStorage.getItem(`${_content + '_' + _mode}`) === null ||
        localStorage.getItem(`${_content + '_' + _mode}`) === undefined
      ) {
        localStorage.setItem(
          `${_content + '_' + _mode}`,
          JSON.stringify(responseData)
        )
      } else {
        const existingData = JSON.parse(
          getDataFromLocalStorage(`${_content + '_' + _mode}`)
        )
        const keys = existingData.map((item) => item.url)
        for (const i in responseData) {
          let currentIndex
          if (
            keys.find((j, index) => {
              if (j === responseData[i].url) {
                currentIndex = index
                return j
              }
            })
          ) {
            responseData[i] = existingData[currentIndex]
            currentIndex = null
          }
        }
        localStorage.setItem(
          `${_content + '_' + _mode}`,
          JSON.stringify(responseData)
        )
      }
    } catch (error) {
      setIsError(true)
      setErrorMessage(() =>
        _content === 'Video'
          ? `${VIDEO_PHOTO_ERROR_MESSAGE} Video`
          : `${VIDEO_PHOTO_ERROR_MESSAGE} Photo`
      )
    }
  }

  useEffect(() => {
    if (videoList && videoList.length === 0) {
      const _content = window.location.pathname.includes('wellness-videos')
        ? 'Video'
        : 'Photo'
      const _mode = window.location.pathname.toLowerCase().includes('common')
        ? 'Common'
        : 'Personal'
      localStorage.setItem('Content', _content)
      localStorage.setItem('Mode', _mode)
      setContent(() => _content)
      setMode(() => _mode)
      getData(_content, _mode)
    }
  }, [])

  useEffect(() => {
    if (videoList && videoList.length > 0) {
      if (slideCount > videoList.length) {
        setSlideCount(() => videoList.length)
      }
      if (window.location.pathname.includes('wellness-photos')) {
        let newArray = []
        const photoListData = JSON.parse(getDataFromLocalStorage(`${'Photo' + '_' + localStorage.getItem('Mode')}`)) &&
          JSON.parse(getDataFromLocalStorage(`${'Photo' + '_' + localStorage.getItem('Mode')}`))
        for(const index in photoListData) {
          if(index < slideCount) {
            newArray.push(<img
              height='100%'
              width='100%'
              src={photoListData[index].url}
              alt={photoListData[index].title}
              onError={() => handleImageError()}

            />)
          }
        }
        if (newArray && newArray.length > 0) {
          setPhotoUrlList(() => newArray)
        }
      }
    }
  }, [videoList.length])

  const OPTIONS = {
    loop: true,
    startIndex: selectedVideoIndex,
    inViewThreshold: 1,
  }

  const navigate = useNavigate()
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS, [ClassNames()])
  let listArray =
    videoList && videoList.length > 0
      ? JSON.parse(
        getDataFromLocalStorage(
          `${
            (content || localStorage.getItem('Content')) +
            '_' +
            (mode || localStorage.getItem('Mode'))
          }`
        )
      )
      : []

  const handleChange = (event) => {
    let listArray = JSON.parse(
      getDataFromLocalStorage(
        `${
          (content || localStorage.getItem('Content')) +
          '_' +
          (mode || localStorage.getItem('Mode'))
        }`
      )
    )
    //Skip preventDefault for back key. So that browser will do the default action
    if(event.keyCode != KeyCode.KEY_BACK){
      event.preventDefault()
      event.stopPropagation()
    }
    if (event.keyCode === KeyCode.KEY_RIGHT) {
      setSelectedVideoIndex(() =>
        getCurrentSelectedIndex(event.keyCode, selectedVideoIndex, listArray)
      )
      emblaApi &&
        emblaApi.scrollTo(
          getCurrentSelectedIndex(event.keyCode, selectedVideoIndex, listArray)
        )
    } else if (event.keyCode === KeyCode.KEY_LEFT) {
      if (selectedVideoIndex === 0) return
      setSelectedVideoIndex(() =>
        getCurrentSelectedIndex(event.key, selectedVideoIndex, listArray)
      )
      emblaApi &&
        emblaApi.scrollTo(
          getCurrentSelectedIndex(event.key, selectedVideoIndex, listArray)
        )
    } else if (event.keyCode === KeyCode.KEY_ENTER) {
      listArray[selectedVideoIndex] = {
        ...listArray[selectedVideoIndex],
        visited: true,
      }
      updateLocalStorage(
        `${
          (content || localStorage.getItem('Content')) +
          '_' +
          (mode || localStorage.getItem('Mode'))
        }`,
        listArray
      )
      if (
        (content || localStorage.getItem('Content')) === 'Video' &&
        listArray[selectedVideoIndex].provider === 'YouTube'
      ) {
        let video_id = getYoutubeId(listArray[selectedVideoIndex].url)
        if(!video_id) {
          setIsError(() => true)
          setErrorMessage(() => localStorage.getItem('Content') === "Video" ? `${VIDEO_PHOTO_ERROR_MESSAGE} Video` : `${VIDEO_PHOTO_ERROR_MESSAGE} Photo`)
        }else {
          navigate(
            `/wellness-videos/${
              mode || localStorage.getItem('Mode')
            }/video/${video_id}`
          )
        }
      } else {
        if ((content || localStorage.getItem('Content')) === 'Video') {
          navigate({
            pathname: `/wellness-videos/${
              mode || localStorage.getItem('Mode')
            }/video/mp4/`,
            search: `?url=${listArray[selectedVideoIndex].url}`,
          })
        } else {
          navigate({
            pathname: `/wellness-photos/${
              mode || localStorage.getItem('Mode')
            }/photo`,
            search: `?url=${listArray[selectedVideoIndex].url}`,
          })
        }
        setshowNavigateButton(false)
      }
      setToggleListerner(() => !toggleLister)
    }else {
      setToggleListerner(() => !toggleLister)
    }
    document.removeEventListener('keydown', handleChange)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleChange, false)
    if(selectedVideoIndex === photoUrlList.length - 3) {
      setPhotoUrlList((currentSlides) => {
        if (currentSlides.length === listArray.length) {
          return currentSlides
        }
        const newSlideCount = currentSlides.length + 5 <= listArray.length  ? currentSlides.length + 5 : currentSlides.length + (listArray.length  - currentSlides.length)
        setSlideCount(() => newSlideCount)
        let newArray = []
        for(const index in listArray) {
          if(Number(index) < newSlideCount && Number(index) >= currentSlides.length) {
            newArray.push(<img
              height='100%'
              width='100%'
              src={listArray[index].url}
              alt={listArray[index].title}
              onError={() => handleImageError()}

            />)
          }
        }
        return [...photoUrlList, ...newArray]
      })
  }
    
  }, [selectedVideoIndex, toggleLister])

  useEffect(() => {
    setshowNavigateButton(true)
  }, [])

  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', handleChange)
    }
  }, [])

  const emblaSlides = useMemo(() => {
    return  videoList &&
    videoList.length > 0 && getDataList(JSON.parse(
      getDataFromLocalStorage(
        `${
          (content || localStorage.getItem('Content')) +
          '_' +
          (mode || localStorage.getItem('Mode'))
        }`
      )
    ))
  },[JSON.parse(getDataFromLocalStorage(`${(content || localStorage.getItem('Content')) +'_' +(mode || localStorage.getItem('Mode'))}`))])


  return (
    <>
      <Layout
        footer={
          <Footer
            rightLabel={
              <>
                <CSDate />
                <br />
                <ShowTimer />
              </>
            }
          />
        }
        data={listArray.length > 1 ? listArray : null}
      >
        <Header
          attributes={{
            pb: '3vh',
          }}
          icon={
            localStorage.getItem('Content') === 'Video'
              ? VideoAppIcon
              : PhotoAppIcon
          }
        />
        <Box className='sandbox'>
          <Box className='sandbox__carousel'>
            <EmblaCarousel
              slides={SLIDES}
              emblaSlides={emblaSlides}
              options={OPTIONS}
              emblaRef={emblaRef}
              emblaApi={emblaApi}
              content={content || localStorage.getItem('Content')}
              selectedVideoIndex={selectedVideoIndex}
            />
          </Box>
        </Box>
        {photoUrlList &&
          photoUrlList.length > 0 &&
          photoUrlList.map((item) => {
            return (
              <Box sx={{ width: '0px', opacity: 0, position: 'absolute' }}>
                {item}
              </Box>
            )
          })}
      </Layout>
    </>
  )
}

export default ThumbnailListView
