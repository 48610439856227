import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { MediaContext } from '../../util/mediaContext'
import { getDataFromLocalStorage, getDataList, handleImageError } from '../../util/common.util'
import PhotoSlider from "../EmblaCarousel/PhotoSlider"
import { KeyCode } from '../../util/keycode'

export const PhotoViewer = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url');
  const {
    mode,
    setToggleListerner,
    toggleLister,
    videoList,
    content,
    deviceId,
    photoUrlList,
    selectedVideoIndex,
    setSlideCount
  } = useContext(MediaContext)

const [slidelist, setSlideList] = useState(photoUrlList);

  useEffect(() => {
    if (document.querySelector('[id^="photogrid"]').requestFullscreen) {
      document.querySelector('[id^="photogrid"]').requestFullscreen()
    }
  }, [])

  const handleChange = (event) => {
      event.preventDefault()
      event.stopPropagation()
    if (event.keyCode === KeyCode.KEY_BACK) {
      navigate(
        `/wellness-photos/${localStorage.getItem('Mode')}${
          localStorage.getItem('Mode') === 'Personal'
            ? `?deviceuuid=${deviceId}`
            : ''
        }`
      )
    } else {
      setToggleListerner(() => !toggleLister)
    }
    document.removeEventListener('keydown', handleChange)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleChange, false)
  }, [toggleLister])

  useEffect(() => {
    if(selectedVideoIndex === slidelist.length - 3) {
      setSlideList((currentSlides) => {
        if (currentSlides.length === videoList.length) {
          return currentSlides
        }
        const newSlideCount = currentSlides.length + 5 <= videoList.length  ? currentSlides.length + 5 : currentSlides.length + (videoList.length  - currentSlides.length)
        setSlideCount(() => newSlideCount)
        let newArray = []
        for(const index in videoList) {
          if(Number(index) < newSlideCount && Number(index) >= currentSlides.length) {
            newArray.push(<img
              height='100%'
              width='100%'
              src={videoList[index].url}
              alt={videoList[index].title}
              onError={() => handleImageError()}

            />)
          }
        }
        return [...slidelist, ...newArray]
      })
  }


  },[selectedVideoIndex])


  const slides = useMemo(() => {
    return videoList &&
    videoList.length > 0 && getDataList(JSON.parse(
      getDataFromLocalStorage(
        `${
          (content || localStorage.getItem('Content')) +
          '_' +
          (mode || localStorage.getItem('Mode'))
        }`
      )
    ))
  },[JSON.parse(getDataFromLocalStorage(`${(content || localStorage.getItem('Content')) +'_' +(mode || localStorage.getItem('Mode'))}`))])
   

  return (
    <div id='photogrid'>
      <PhotoSlider propSlides={slidelist} listArray={slides} url={url}/>
    </div>
  )
}
