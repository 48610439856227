import { createTheme } from '@mui/material/styles'
import '../index.css'

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 321,
      md: 770,
      lg: 1026,
      xl: 1202,
    },
  },
  palette: {
    primary: {
      main: '#0072BC',
      light: '#CBDCF6',
      dark: '#00204A',
    },

    secondary: {
      main: '#AEAEAE',
      light: '#F1F1F1',
      dark: '#3F3F3F',
    },
    background: {
      paper: '#F9F9FC',
      default: '#F9F9FC',
    },
  },

  typography: {
    fontFamily: "'Roboto Flex', sans-serif",
    h1: {
      fontSize: 'clamp(1.28rem, 3.3vmin + 3.3vmax, 5.99rem)',
    },
    h2: {
      fontSize: 'clamp(1.0417rem, 2.1vmin + 2.1vmax, 3.75rem)',
    },
    h3: {
      fontSize: 'clamp(1.0711rem, 1.66vmin + 1.66vmax, 2.9991rem)',
    },
    h4: {
      fontSize: 'clamp(1.0121rem, 1.12vmin + 1.12vmax, 2.0243rem)',
    },
    h5: {
      fontSize: 'clamp(0.937rem, 0.83vmin + 0.83vmax, 1.4993rem)',
    },
  },
})

// theme = responsiveFontSizes(theme, {
//   breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
//   factor: 20,
// })

export default theme
