import { Box } from '@mui/material'

export function Header({ attributes, icon, centerItem }) {
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 50px auto 50px)',
          px: { xs: 0.5, sm: 8 },
          ...attributes,
        }}
      >
        <Box sx={{ alignSelf: 'center' }}>
          <img
            src={icon}
            alt='app-icon'
            style={{
              height: '4vh',
              width: '4vw',
            }}
          />
        </Box>

        <Box>{centerItem}</Box>
        <Box></Box>
      </Box>
    </>
  )
}
