import MR_CONFIG from '../asset/mr-type.config.json'

export const DATE_FORMAT_1 = 'MMMM DD, YYYY, h:mm A'
export const TIME_FORMAT_AM_PM = 'h:mm A'
export const DAYS = 'days'
export const HOURS = 'hours'
// labels, messages, texts, captions

export const NO_RECENT_READINGS_FOUND = 'No recent readings found.'
export const MEDICAL_REACING_ERROR_MESSAGE =
  'Complete a health reading on a connected device and it will appear here for easy viewing.'
export const MEDIACL_ERROR_SUPPORT_TEXT = 'Support is available for:'
export const VIDEO_PHOTO_ERROR_MESSAGE = 'Unable to Load'
export const SECONDARY_ERROR_MESSAGE = 'There was an error Loading this'
export const TODAY = 'Today'
export const TOP = 'Top'
export const YESTERDAY = 'Yesterday'

// local storage
export const MR_DB_LOCAL_STORAGE = 'MEDICAL_READING'
export const MR_HDB_LOCAL_STORAGE = 'MEDICAL_READING_HISTORY'
export const MR_DB = (() => {
  const db_obj = {}
  Object.keys(MR_CONFIG).forEach((element) => (db_obj[element] = ''))
  return db_obj
})()
