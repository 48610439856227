import { useEffect, useState, useContext } from 'react'
import { Box } from '@mui/material'
import {
  createLocalStorage,
  DAYS,
  TOP,
  getDateFromNow,
  HOURS,
  isDateToday,
  MR_DB,
  NO_RECENT_READINGS_FOUND,
  useQuery,
  removeLocalStorage,
  MEDICAL_REACING_ERROR_MESSAGE,
  MR_DB_LOCAL_STORAGE,
  MR_HDB_LOCAL_STORAGE,
  getDataFromLocalStorage,
} from '../../util'
import { getMedicalReadingData } from './medical-reading.api'
import { MediaContext } from '../../util/mediaContext'
import {
  checkEmptyDB,
  generateTimeRangeStr,
  getBottomValue,
  getFormattedMRData,
  getSortedData,
  getTopValue,
  updateMRDataToLS,
} from './medical-reading.util'
import { BottomReading, CenterDivision, MainReading } from './templates'
import { Footer, Header, Layout, TextBlock } from '../common-templates'
import { DateBlock } from './templates/date-block.template'
import { MedicalAppIcon } from '../../asset'

function MedicalReading() {
  let { setIsError, setErrorMessage } = useContext(MediaContext)
  const [topDisplayValues, setTopDisplayValues] = useState(null)
  const [bottomDisplayValues, setBottomDisplayValues] = useState(null)
  const [formattedData, setFormattedData] = useState(null)
  const query = useQuery()

  const populateDashboard = (formattedMRData) => {
    const topValue = getTopValue(formattedMRData)
    const bottomValue = getBottomValue(formattedMRData)
    setTopDisplayValues(topValue)
    setBottomDisplayValues(bottomValue)
  }

  const processMRData = (medicalReadingData) => {
    let formattedMRData = ''
    if (medicalReadingData?.events?.length > 0) {
      // format data
      formattedMRData = getFormattedMRData(medicalReadingData.events)
    } // check if db both db are empty
    else if (
      checkEmptyDB(MR_DB_LOCAL_STORAGE) &&
      checkEmptyDB(MR_HDB_LOCAL_STORAGE)
    ) {
      // eslint-disable-next-line no-throw-literal
      throw 'error'
    } else {
      // show data from DB

      // get db data
      const lsDBData = JSON.parse(getDataFromLocalStorage(MR_DB_LOCAL_STORAGE))
      const lsHDBData = JSON.parse(
        getDataFromLocalStorage(MR_HDB_LOCAL_STORAGE)
      )

      // make array of non empty entries only
      const medicalReadingData = [
        ...(lsDBData && Object.values(lsDBData).filter((item) => item && item)),
        ...(lsHDBData &&
          Object.values(lsHDBData).filter((item) => item && item)),
      ]

      formattedMRData = getSortedData(medicalReadingData)
    }

    setFormattedData(formattedMRData)
    // populate data
    populateDashboard(formattedMRData)
  }

  const getMRData = async (trange, tunit) => {
    // get data
    try {
      const medicalReadingData = await getMedicalReadingData(
        generateTimeRangeStr(trange, tunit),
        query.get('deviceuuid'),
        query.get('token'),
        setIsError,
        setErrorMessage
      )
      processMRData(medicalReadingData)
    } catch (error) {
      setIsError(true)
      setErrorMessage(MEDICAL_REACING_ERROR_MESSAGE)
    }
  }

  useEffect(() => {
    // create local storage if not done already for medical record
    createLocalStorage(MR_DB_LOCAL_STORAGE, MR_DB)
    createLocalStorage(MR_HDB_LOCAL_STORAGE, MR_DB)
    removeLocalStorage('Content')
    removeLocalStorage('Mode')

    getMRData(30, DAYS)
    const repeatAPICall = setInterval(() => {
      getMRData(5, HOURS)
    }, 10000)

    return () => clearInterval(repeatAPICall)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formattedData) {
      // update local storage
      updateMRDataToLS(formattedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomDisplayValues])

  return (
    <>
      <Layout
        data={topDisplayValues}
        footer={<Footer rightLabel={topDisplayValues?.typeLabel} />}
      >
        <Header
          icon={MedicalAppIcon}
          centerItem={
            <DateBlock
              text1={
                topDisplayValues && isDateToday(topDisplayValues?.device_time)
              }
              text2={
                topDisplayValues &&
                `${getDateFromNow(topDisplayValues?.device_time, TOP)}`
              }
              variant={'h4'}
            />
          }
        />

        <Box sx={{ my: { xs: 3, sm: '8vh' }, mx: { xs: 0.5 } }}>
          <CenterDivision
            {...(topDisplayValues?.value2 && {
              isBorderColor: 'primary.light',
              isDivision: true,
            })}
            leftBlock={
              <MainReading
                label={topDisplayValues && topDisplayValues.value1Label}
                value={topDisplayValues?.value1}
                img={topDisplayValues && topDisplayValues.image1}
                unit={topDisplayValues && topDisplayValues.unit1}
              />
            }
            rightBlock={
              topDisplayValues?.value2 ? (
                <MainReading
                  label={topDisplayValues && topDisplayValues.value2Label}
                  value={topDisplayValues?.value2}
                  img={topDisplayValues && topDisplayValues.image2}
                  unit={topDisplayValues && topDisplayValues.unit2}
                />
              ) : null
            }
          />
        </Box>
        <Box sx={{ px: { xs: 0.5, sm: 6, md: 12, lg: 20, xl: 28 } }}>
          <Box
            sx={{
              bgcolor: 'secondary.light',
              borderRadius: '16px',
              py: { xs: 1, sm: '3vh' },
            }}
          >
            {bottomDisplayValues !== NO_RECENT_READINGS_FOUND ? (
              <>
                <Box sx={{ mb: { xs: 1, sm: '3vh' } }}>
                  <DateBlock
                    text1={
                      bottomDisplayValues &&
                      isDateToday(bottomDisplayValues?.device_time)
                    }
                    text2={
                      bottomDisplayValues &&
                      `${getDateFromNow(bottomDisplayValues?.device_time)}`
                    }
                    variant={'h5'}
                  />
                </Box>

                <CenterDivision
                  {...(topDisplayValues?.value2 && {
                    isBorderColor: 'secondary.main',
                    isDivision: true,
                  })}
                  leftBlock={
                    <BottomReading
                      label={
                        bottomDisplayValues && bottomDisplayValues.value1Label
                      }
                      value={bottomDisplayValues?.value1}
                      unit={bottomDisplayValues && bottomDisplayValues.unit1}
                    />
                  }
                  rightBlock={
                    bottomDisplayValues?.value2 ? (
                      <BottomReading
                        label={
                          bottomDisplayValues && bottomDisplayValues.value2Label
                        }
                        value={bottomDisplayValues?.value2}
                        unit={bottomDisplayValues && bottomDisplayValues.unit2}
                      />
                    ) : null
                  }
                />
              </>
            ) : (
              <TextBlock
                text={NO_RECENT_READINGS_FOUND}
                variant='h4'
                attributes={{
                  color: 'primary.main',
                  textAlign: 'center',
                  py: 5,
                }}
              />
            )}
          </Box>
        </Box>
      </Layout>
    </>
  )
}

export default MedicalReading
