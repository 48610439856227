import { Box } from '@mui/material'

export const CenterDivision = ({
  isDivision,
  isBorderColor,
  leftBlock,
  rightBlock,
}) => {
  const noOfColumns = isDivision ? 2 : 1
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${noOfColumns}, 1fr)`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            ...(isDivision
              ? {
                  justifyContent: 'flex-end',
                  pr: { xs: 1, sm: 3, md: 5, lg: 6, xl: 10 },
                }
              : {
                  justifyContent: 'center',
                }),
            ...(isBorderColor
              ? {
                  border: 0,
                  borderRight: 2,
                  borderStyle: 'dashed',
                  borderColor: isBorderColor,
                }
              : null),
          }}
        >
          {leftBlock}
        </Box>
        <Box sx={{ pl: { xs: 1, sm: 3, md: 5, lg: 6, xl: 10 } }}>
          {rightBlock && rightBlock}
        </Box>
      </Box>
    </>
  )
}
