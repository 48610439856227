import React, { useContext, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { MediaContext } from '../../util/mediaContext'
import { handleImageError } from '../../util/common.util'

//Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Autoplay } from 'swiper'

export default function PhotoSlider({ propSlides, listArray, url }) {
  let {
    selectedVideoIndex,
    setSelectedVideoIndex,
    videoList
  } = useContext(MediaContext)

  const [allowSlideChange, setAllowSLideChange] = useState(false)

  const handleSlideChange = () => {
    if(selectedVideoIndex >= videoList.length - 1) {
      setSelectedVideoIndex(0)
    }
    else if (selectedVideoIndex === 0 || (selectedVideoIndex > 0 && allowSlideChange)) {
      setSelectedVideoIndex(() => selectedVideoIndex + 1);
      setAllowSLideChange(true);
    } else {
      setAllowSLideChange(true);
      setSelectedVideoIndex(() => selectedVideoIndex)
    }
  }

  return (
    <>
      {propSlides.length > 0 && listArray.length ? <Swiper
        style={{
          height: '100%', width: '100%'
        }}
        initialSlide={selectedVideoIndex}
        autoplay={{
          delay: window.envConfig.SLIDER_INTERVAL * 1000,
          disableOnInteraction: false
        }}
        onSlideChange={() => handleSlideChange()}
        modules={[Autoplay]}
      >
        {propSlides && propSlides.length > 0
          ? propSlides.map((item) => {
            return <SwiperSlide
              style={{
                height: '100%', width: '100%'
              }}
              loading="lazy">{item}</SwiperSlide>
          })
          : null}
      </Swiper> : <>
        <img
          height='100%'
          width='100%'
          src={url}
          alt="Image"
          onError={() => handleImageError()}
        />
      </>}
    </>
  )
}
