import React, { useContext } from 'react'
import { Box, Paper } from '@mui/material'
import { HomeSightLogo, Common_videos_logo } from '../../asset'
import { TextBlock } from './text-block.template'
import { getWeekName, gethumanizedTimeValue } from '../../util'
import moment from 'moment'
import { SVG } from './index'
import { MediaContext } from '../../util/mediaContext'



export const Footer = ({ rightLabel, actionButtons }) => {
  let {
    content,
    mode
  } = useContext(MediaContext)
  return (
    <>
      <Box
        sx={{ p: { xs: 1, sm: '3.3vh' }, px: { xs: 0.5, sm: 8 } }}
        component={Paper}
        square={true}
        elevation={0}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(3, 1fr)`,
          }}
        >
          <Box sx={{ alignSelf: 'center' }}>
            {(content === "Video" && mode === "Common") ?
              <SVG
                src={Common_videos_logo}
                alt='Common Videos Logo'
                containerHeight='8vh'
                containerWidth='7vw'
              />
              :
              <SVG
                src={HomeSightLogo}
                alt='logo'
                containerHeight='8vh'
                containerWidth='7vw'
              />
            }
          </Box>
          <TextBlock
            text={
              <>
                {getWeekName(moment().format())}
                <br />
                {gethumanizedTimeValue(moment().format('HH'))}
              </>
            }
            variant='h4'
            attributes={{
              textTransform: 'capitalize',
              color: 'primary.main',
              fontWeight: 'bold',
              textAlign: 'center',
              alignSelf: 'center',
            }}
          />
          <TextBlock
            text={rightLabel}
            variant='h4'
            attributes={{
              textTransform: 'capitalize',
              color: 'primary.main',
              fontWeight: 'bold',
              textAlign: 'right',
              alignSelf: 'center',
            }}
          />
        </Box>
      </Box>
    </>
  )
}
