import { Box } from '@mui/material'

export const SVG = ({ src, alt, containerHeight, containerWidth }) => {
  return (
    <Box
      sx={{
        ...(containerHeight && { height: containerHeight }),
        ...(containerWidth && { width: containerWidth }),
      }}
    >
      <img src={src} alt={alt} height='100%' width='100%' />
    </Box>
  )
}
