import { Box } from '@mui/material'
import { TextBlock } from '../../common-templates'

export const BottomReading = ({ label, value, unit }) => {
  return (
    <>
      <Box>
        <TextBlock
          text={label}
          variant='h4'
          attributes={{
            textTransform: 'capitalize',
            color: 'secondary.dark',
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          <TextBlock
            text={value}
            variant='h3'
            attributes={{
              fontWeight: 'bold',
            }}
          />

          {unit && (
            <TextBlock
              text={unit}
              variant='h4'
              attributes={{
                pl: 1,
              }}
            />
          )}
        </Box>
      </Box>
    </>
  )
}
