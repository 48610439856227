import axios from 'axios'
import { MEDICAL_REACING_ERROR_MESSAGE } from '../../util'

export const getMedicalReadingData = (
  timeRange,
  deviceUUID,
  token,
  setIsError,
  setErrorMessage
) => {
  var payload = JSON.stringify({
    eventtypes: ['device_medical_reading_event'],
  })

  var config = {
    method: 'put',
    url: `${window.envConfig.MR_DOMAIN}/hacloud/api/v1/ecs/events/${deviceUUID}/eventtypes/${timeRange}`,
    data: payload,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  return axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      setIsError(true)
      setErrorMessage(MEDICAL_REACING_ERROR_MESSAGE)
    })
}
