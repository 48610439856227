import React, { useContext } from 'react'
import { Footer, Layout } from '../common-templates'
import { Box } from '@mui/material'
import { MediaContext } from '../../util/mediaContext'
import { WarningIcon } from '../../asset'
import { TextBlock } from '../common-templates'
import {
  SECONDARY_ERROR_MESSAGE,
  MEDICAL_REACING_ERROR_MESSAGE,
  MEDIACL_ERROR_SUPPORT_TEXT,
} from '../../util'
import MR_CONFIG from '../../asset/mr-type.config.json'

const ErrorBoundary = ({ isError, children }) => {
  let { errorMessage } = useContext(MediaContext)
  if (isError) {
    return (
      <>
        <Layout footer={<Footer />} data='error'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem',
            }}
          >
            {localStorage.getItem('Content') === 'Video' ||
            localStorage.getItem('Content') === 'Photo' ? (
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <img src={WarningIcon} />
                <TextBlock
                  variant='h3'
                  text={errorMessage}
                  attributes={{
                    textAlign: 'center',
                    lineHeight: '38px',
                    color: 'primary.main',
                    marginTop: '1rem',
                  }}
                />
                <TextBlock
                  variant='h5'
                  text={
                    localStorage.getItem('Content') === 'Video'
                      ? `${SECONDARY_ERROR_MESSAGE} video.`
                      : `${SECONDARY_ERROR_MESSAGE} photo.`
                  }
                  attributes={{
                    textAlign: 'center',
                    lineHeight: '38px',
                    color: 'primary.main',
                    marginTop: '2rem',
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <TextBlock
                  variant='h5'
                  text={MEDICAL_REACING_ERROR_MESSAGE}
                  attributes={{
                    textAlign: 'center',
                    lineHeight: '38px',
                    marginTop: '2rem',
                  }}
                />
                <TextBlock
                  variant='h5'
                  text={MEDIACL_ERROR_SUPPORT_TEXT}
                  attributes={{
                    lineHeight: '38px',
                    marginTop: '2rem',
                    textAlign: 'center',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: 'auto',
                  }}
                >
                  {Object.keys(MR_CONFIG).map((item) => (
                    <TextBlock
                      key={item}
                      variant='h6'
                      text={item.replace('_', ' ')}
                      attributes={{
                        textAlign: 'start',
                        textTransform: 'capitalize',
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Layout>
      </>
    )
  } else {
    return children
  }
}

export default ErrorBoundary
