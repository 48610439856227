import React, { useState, useRef } from 'react'
import MainRoute from './mainRoute'
import { MediaContext } from '../../util/mediaContext'

const Routing = () => {
  const [videoList, setVideoList] = useState([])
  const [content, setContent] = useState('')
  const [mode, setMode] = useState('')
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0)
  const [VideoPlaying, setVideoPlaying] = useState(false)
  const [videoOptions, setVideoOptions] = useState({})
  const [showNavigateButton, setshowNavigateButton] = useState(true)
  const [toggleLister, setToggleListerner] = useState(false)
  const [isError, setIsError] = useState(false)
  const [slideCount, setSlideCount] = useState(6)
  const [errorMessage, setErrorMessage] = useState("")
  const [deviceId, setDeviceId] = useState('');
  let [photoUrlList, setPhotoUrlList] = useState([])

  let player = useRef(null)
  let videoRef = useRef(null)
  let timeout


  const data = {
    videoList,
    selectedVideoIndex,
    setSelectedVideoIndex,
    VideoPlaying,
    setVideoPlaying,
    videoOptions,
    setVideoOptions,
    showNavigateButton,
    setshowNavigateButton,
    player,
    videoRef,
    timeout,
    content,
    mode,
    setToggleListerner,
    toggleLister,
    setIsError,
    isError,
    setVideoList,
    setContent,
    setMode,
    slideCount,
    setSlideCount,
    errorMessage,
    setErrorMessage,
    setDeviceId,
    deviceId,
    setPhotoUrlList,
    photoUrlList
  }

  return (
    <MediaContext.Provider value={{ ...data }}>
       <MainRoute />
    </MediaContext.Provider>
  )
}

export default Routing
