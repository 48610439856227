import { Box, Typography } from '@mui/material'

export const TextBlock = ({ text, attributes, variant }) => {
  return (
    <>
      <Box sx={{ ...attributes }} component={Typography} variant={variant}>
        {text}
      </Box>
    </>
  )
}
