import { Routing } from './components';
import "./index.css";

function App() {
  return (
    <>
      <Routing />
    </>
  )
}

export default App
