import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { TODAY, YESTERDAY, DATE_FORMAT_1, TIME_FORMAT_AM_PM } from './index'

export function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function getFormattedDate(date, format) {
  return date ? moment(date).format(format) : null
}

export function getDateFromNow(date, placeHolder) {
  return date
    ? moment(date).isSame(new Date(), 'day')
      ? placeHolder === 'Top'
        ? `${moment(date).format(TIME_FORMAT_AM_PM)}`
        : `${moment(date).format(DATE_FORMAT_1)}`
      : `${moment(date).format(DATE_FORMAT_1)}`
    : null
}

export function getWeekName(mydate) {
  var weekDayName = moment(mydate).format('dddd')
  return weekDayName
}

export function getFooterDate() {
  return moment()
    .zone(0)
    .format('MMMM D, YYYY')
    .replace(/(st|nd|rd|th)[, ]+/g, ' ')
}

export function getFootertime() {
  return moment().format(TIME_FORMAT_AM_PM)
}

export function gethumanizedTimeValue(currentTime) {
  const split_afternoon = 12
  const split_evening = 16
  const split_night = 20
  let humanizedValue = 'Morning'

  if (currentTime >= split_afternoon && currentTime < split_evening) {
    humanizedValue = 'Afternoon'
  } else if (currentTime >= split_evening && currentTime < split_night) {
    humanizedValue = 'Evening'
  } else if (currentTime >= split_night) {
    humanizedValue = 'Night'
  }

  return humanizedValue
}

export function isDateToday(date) {
  return date
    ? moment(date).isSame(new Date(), 'day')
      ? TODAY
      : Math.round(moment.duration(moment() - moment(date)).asDays()) === 1
      ? YESTERDAY
      : moment(date).fromNow()
    : null
}

export function readCurrentTime() {
  return moment().format(TIME_FORMAT_AM_PM)
}

export function createLocalStorage(key, data) {
  // check if key exist
  const keyExists = localStorage.getItem(key) !== null
  // create entry in local storage
  if (!keyExists) {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export function getDataFromLocalStorage(key) {
  // check if key exist
  const keyExists = localStorage.getItem(key) !== null
  // return local storage data

  if (!keyExists) return null

  return localStorage.getItem(key)
}

export function removeLocalStorage(key) {
  // check if key exist
  const keyExists = localStorage.getItem(key) !== null
  //remove entry in local storage
  if (keyExists) {
    localStorage.removeItem(key)
  }
}

export function updateLocalStorage(key, data) {
  // check if key exist
  const keyExists = localStorage.getItem(key) !== null
  // create entry in local storage
  if (keyExists) {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export const getYoutubeId = (url) => {
  let video_id = url.split('v=')[1]
  var ampersandPosition = video_id.indexOf('&')
  if (ampersandPosition !== -1) {
    video_id = video_id.substring(0, ampersandPosition)
  }
  return video_id
}

const getTime = (mode, player) => {
  const currrent_time =
    mode === 'YouTube'
      ? player && player.current
        ? Math.round(player.current.getCurrentTime())
        : null
      : player && player.current
      ? Math.round(player.current.currentTime)
      : null
  return currrent_time
}

export const setLastSeenVideoTime = (
  content,
  mode,
  selectedVideoIndex,
  player,
  playerMode,
  _val
) => {
  let listArray = JSON.parse(getDataFromLocalStorage(`${content + '_' + mode}`))
  const playedTime = getTime(playerMode, player)
  if (_val || playedTime) {
    listArray[selectedVideoIndex] = {
      ...listArray[selectedVideoIndex],
      lastVisitedTime: _val ? 0 : playedTime,
    }
  }
  updateLocalStorage(`${content + '_' + mode}`, listArray)
}

export const handleImageError = (ev) => {
  ev.target.src =
    '../asset/Errorloading.jpg'
}

export const getPlayerCurrentTime = (_time = 0) =>
  (_time !== undefined || _time !== null) &&
  new Date(_time * 1000).toISOString().slice(14, 19)

export const getPlayerDuration = (_time = 0) =>
  (_time !== undefined || _time !== null) &&
  new Date((_time + 1) * 1000).toISOString().slice(14, 19)

export const getCurrentSelectedIndex = (
  keyStroke,
  selectedIndex,
  listArray
) => {
  let tempIndex = selectedIndex
  if (keyStroke === 39) {
    tempIndex =
      selectedIndex >= 0 && selectedIndex < listArray.length - 1
        ? selectedIndex + 1
        : 0
  } else {
    tempIndex =
      selectedIndex > 0 &&
      selectedIndex <= listArray.length - 1 &&
      selectedIndex - 1
  }
  return tempIndex
}


export const getCurrentProgressTime = (content, mode, selectedVideoIndex,  MAX) => {
  const currentVideo = JSON.parse(getDataFromLocalStorage(`${(content || localStorage.getItem("Content")) + "_" + (mode || localStorage.getItem("Mode"))}`));

  return  currentVideo && currentVideo[selectedVideoIndex] && currentVideo[selectedVideoIndex].lastVisitedTime &&
          currentVideo[selectedVideoIndex].lastVisitedTime >= MAX ? 0 :
          currentVideo && currentVideo[selectedVideoIndex] && currentVideo[selectedVideoIndex].lastVisitedTime ? 
          (currentVideo[selectedVideoIndex].lastVisitedTime * 100 / MAX) + 100 / MAX : 0
}


export const getCurrentPlayingTime = (content,mode,selectedVideoIndex) => {
  const currentVideo = JSON.parse(getDataFromLocalStorage(`${(content || localStorage.getItem("Content")) + "_" + (mode || localStorage.getItem("Mode"))}`));
  return currentVideo && 
  currentVideo[selectedVideoIndex] &&
  currentVideo[selectedVideoIndex].lastVisitedTime ? 
  currentVideo[selectedVideoIndex].lastVisitedTime : 0 
}


export const getDataList = (list) => {
    
  let newList = list.map((item, i) => item)
  return newList
 }
