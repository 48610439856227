import React, { useState, useEffect, useContext, useRef } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { TextBlock } from '../common-templates/text-block.template'
import Box from '@mui/material/Box'
import { MediaContext } from '../../util/mediaContext'
import { getPlayerCurrentTime, getPlayerDuration } from "../../util/common.util"
import { getCurrentProgressTime, getCurrentPlayingTime } from '../../util/common.util'

const LinearProgressWithLabel = (props) => {
  const { currentPlayingTime = 0, videoLength } = props

  return (
    videoLength && <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        px: 4,
      }}
    >
      <Box sx={{ flexGrow: 1, pr: 3 }}>
        <LinearProgress
          sx={{
            backgroundColor: 'secondary.light',
            height: '10px',
            borderRadius: '25px',
          }}
          variant='determinate'
          {...props}
        />
      </Box>
      <Box>
        <TextBlock
          text={`${currentPlayingTime !== null && currentPlayingTime >= 0 && getPlayerCurrentTime(currentPlayingTime)

            } / ${videoLength !== null && videoLength > 0 && getPlayerDuration(videoLength)

            }`}
          variant='h6'
          attributes={{
            color: 'secondary.dark',
          }}
        />
      </Box>
    </Box>
  )
}

function LinearWithValueLabel(props) {
  const { VideoPlaying, selectedVideoIndex, content, mode } = useContext(MediaContext)
  let timer = useRef(null)
  const { MAX, value } = props

  const [progress, setProgress] = useState((value * 100) / MAX)
  const [currentPlayingTime, setCurrentPlayingTime] = useState(value)

  React.useEffect(() => {
    if (VideoPlaying) {
      if (!timer.current) {
        timer.current = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= MAX ? 0 : prevProgress + 100 / MAX
          )
          setCurrentPlayingTime((prevVal) => {
            if (prevVal < MAX) {
              return prevVal + 1
            } else {
              clearInterval(timer.current)
              timer.current = null;
              return 0
            }
          })
        }, 1000)
      }
    } else {
      clearInterval(timer.current)
      timer.current = null;
      return
    }
  }, [VideoPlaying])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel
        videoLength={MAX}
        currentPlayingTime={VideoPlaying ? currentPlayingTime : getCurrentPlayingTime(content, mode, selectedVideoIndex)}
        value={VideoPlaying ? progress : getCurrentProgressTime(content, mode, selectedVideoIndex, MAX)}
        VideoPlaying={VideoPlaying}
      />
    </Box>
  )
}

export default React.memo(LinearWithValueLabel)
