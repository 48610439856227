import React, {useContext} from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ThumbnailListView from '../ThumbnailGridView/Thumbnail-grid.component';
import { VideoPlayers } from "../VideoPlayers";
import { PhotoViewer } from "../PhotoVIewer/photo-viewer-component";
import MedicalReading from '../medical-reading/medical-reading.component'
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary.component";
import { MediaContext } from '../../util/mediaContext'
import DisplayVersion from "../DisplayVersion";




const router = createBrowserRouter([
    { path: '/version/', element: <DisplayVersion /> },
    { path: '/medical-reading/', element: <MedicalReading /> },
    { path: '/wellness-videos/common', element: <ThumbnailListView /> },
    { path: '/wellness-videos/personal', element: <ThumbnailListView /> },
    { path: '/wellness-photos/common', element: <ThumbnailListView /> },
    { path: '/wellness-photos/personal', element: <ThumbnailListView /> },
    { path: `/wellness-videos/common/video/:mediaId`, element: <VideoPlayers /> },
    { path: `/wellness-videos/personal/video/:mediaId`, element: <VideoPlayers /> },
    { path: `/wellness-photos/common/photo/`, element: <PhotoViewer /> },
    { path: `/wellness-photos/personal/photo/`, element: <PhotoViewer /> },
])

const MainRoute = () => {
    let {
       isError
      } = useContext(MediaContext)
    return (
    <ErrorBoundary isError={isError} >
        <RouterProvider router={router} />
    </ErrorBoundary>)
}

export default MainRoute;

