import axios from 'axios'
import { VIDEO_PHOTO_ERROR_MESSAGE } from '../../util'

export const getMediaData = (deviceUUID, _content, _mode, setIsError, setErrorMessage) => {
  const url =
    _content === 'Video'
      ? _mode === 'Personal'
        ? `${window.envConfig.MEDIA_DOMAIN}/personal/videos/${deviceUUID}/videos.json`
        : `${window.envConfig.MEDIA_DOMAIN}/common/videos/wellnessvideos.json`
      : _mode === 'Personal'
      ? `${window.envConfig.MEDIA_DOMAIN}/personal/photos/${deviceUUID}/photos.json`
      : `${window.envConfig.MEDIA_DOMAIN}/common/photos/photos.json`

  var config = {
    method: 'get',
    url: url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  return axios(config)
    .then(function (response) {
      return response.data.info
    })
    .catch(function (error) {
      setIsError(true)
      setErrorMessage(() => _content === "Video" ? `${VIDEO_PHOTO_ERROR_MESSAGE} Video` : `${VIDEO_PHOTO_ERROR_MESSAGE} Photo`)
    })
}
