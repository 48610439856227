import React, { useState, useRef, useEffect } from "react";
import { getFooterDate } from "../../util";
import moment from "moment";

const CSDate = () => {
    const [timer, setTimer] = useState(moment().format('D'))
    const timerRef = useRef(null);
    useEffect(() => {
        timerRef.current = setInterval(() => {
            setTimer(() => moment().format('D'))
          }, 1000)
    },[])

    useEffect(() => {
       return () => clearInterval(timerRef.current)
    },[])

    return getFooterDate()
}

export default CSDate;