import { Box } from '@mui/material'
import { TextBlock } from '../../common-templates'

export const DateBlock = ({ text1, text2, variant }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextBlock
          text={text1}
          variant={variant}
          attributes={{
            fontWeight: 'bold',
          }}
        />
        <TextBlock text={text2} variant={variant} />
      </Box>
    </>
  )
}
