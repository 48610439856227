import MR_CONFIG from '../../asset/mr-type.config.json'

export function getReadingsFromConfig(item) {
  const dataModel = getDataModel(item)

  return {
    type: item.type,
    device_time: item.device_time,
    typeLabel: item.type.split('_').join(' '),
    value1Label: dataModel.value1Label,
    value1: formatValue(item, dataModel, 'value1', 'round_value1'),
    unit1: formatUnit(item, dataModel, 'unit1'),
    image1: dataModel.image1,
    ...(dataModel.value2 && {
      value2Label: dataModel.value2Label,
      value2: formatValue(item, dataModel, 'value2', 'round_value2'),
      unit2: formatUnit(item, dataModel, 'unit2'),
      image2: dataModel.image2,
    }),
  }
}

function getDataModel(item) {
  return MR_CONFIG[item.type]
}

function formatUnit(item, dataModel, unit) {
  return dataModel[unit].includes('hardcoded')
    ? dataModel[unit].split(':')[1]
    : item[dataModel[unit]]
}

function formatValue(item, dataModel, value, round) {
  let finalValue = ''
  if (typeof dataModel[value] !== 'string') {
    if (dataModel[value].format === '/') {
      const keys = dataModel[value].keys.split(',')

      keys.forEach((element, index) => {
        finalValue =
          finalValue + item[element] + (index < keys.length - 1 ? ' / ' : '')
      })
    }
  } else {
    finalValue = item[dataModel[value]]
  }

  return dataModel[round] ? finalValue.toFixed(1) : finalValue
}
