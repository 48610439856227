import moment from 'moment/moment'
import {
  getDataFromLocalStorage,
  HOURS,
  MR_DB_LOCAL_STORAGE,
  MR_HDB_LOCAL_STORAGE,
  NO_RECENT_READINGS_FOUND,
  updateLocalStorage,
} from '../../util'
import { getReadingsFromConfig } from './medical-reading.config'
import MR_CONFIG from '../../asset/mr-type.config.json'

// 1. sortByDate
// 2. format data
export const getFormattedMRData = (data) => {
  // correct date
  const dateCorrectedData = getDateCorrectedData(data)
  // sort data based on date
  const sortedData = getSortedData(dateCorrectedData)
  // format data
  const formattedData = getFormattedData(sortedData)

  return formattedData
}

const getDateCorrectedData = (data) => {
  return data.map((item) => {
    item.device_time = correctDT(item.device_time)
    return item
  })
}

const correctDT = (dateString) => {
  let dt = dateString.split('IST')[0].trim()
  let [date, time] = dt.split(' ')
  let dArray = date.split('-')
  let [d, m, y] = [dArray[1], dArray[0], dArray[2]]
  return `${d}-${m}-${y} ${time}`
}

export const getSortedData = (arr) => {
  return arr.sort((a, b) => {
    // latest date first oldest date last
    return a && b && moment(b.device_time).diff(a.device_time)
  })
}

const getFormattedData = (sortedData) => {
  return sortedData.map((item) => getReadingsFromConfig(item))
}

export const getTopValue = (data) => {
  return data[0]
}

export const getBottomValue = (data) => {
  const type = data[0].type
  const lsDBData = JSON.parse(getDataFromLocalStorage(MR_DB_LOCAL_STORAGE))
  const lsHDBData = JSON.parse(getDataFromLocalStorage(MR_HDB_LOCAL_STORAGE))

  const secondEntry = data.find(
    (item, index) => index > 0 && item.type === type
  )
  if (secondEntry) {
    // check if we have bottom value in the latest list it self
    return secondEntry
  } else if (lsDBData && lsDBData[type] !== '') {
    // from local storage if second entry is not available and if db has data
    if (data[0].device_time === lsDBData[type].device_time) {
      return lsHDBData[type] !== '' ? lsHDBData[type] : NO_RECENT_READINGS_FOUND
    } else {
      return lsDBData[type] !== '' ? lsDBData[type] : NO_RECENT_READINGS_FOUND
    }
  } else {
    // return string "nothing to display".
    return NO_RECENT_READINGS_FOUND
  }
}

export const updateMRDataToLS = (data) => {
  const mrDBData = {}
  const mrHDBData = {}
  const lsDBData = JSON.parse(getDataFromLocalStorage(MR_DB_LOCAL_STORAGE))
  const lsHDBData = JSON.parse(getDataFromLocalStorage(MR_HDB_LOCAL_STORAGE))

  Object.keys(MR_CONFIG).forEach((type) => {
    const firstEntry = data.find((item) => item.type === type)
    const firstEntryIndex = data.findIndex((item) => item.type === type)
    const secondEntry = data.find(
      (item, index) => index > firstEntryIndex && item.type === type
    )

    // Update db with the latest value from data
    mrDBData[type] = fillData(firstEntry, lsDBData[type])

    if (secondEntry) {
      // Update hdb if data has second value
      mrHDBData[type] = fillData(secondEntry, lsHDBData[type])
    } else if (
      firstEntry &&
      firstEntry.device_time !== lsDBData[type].device_time
    ) {
      /*
        If there is no second entry in the data and 
        latest data and db data don't have same device_time 
        then update hdb with db value
      */
      mrHDBData[type] = fillData(lsDBData[type], lsHDBData[type])
    } else {
      // just put the same data which is there in hdb again
      mrHDBData[type] = fillData(null, lsHDBData[type])
    }
  })

  updateLocalStorage(MR_DB_LOCAL_STORAGE, mrDBData)
  updateLocalStorage(MR_HDB_LOCAL_STORAGE, mrHDBData)
}

const fillData = (data, alternateData) => {
  return data ? data : alternateData ? alternateData : ''
}

export const generateTimeRangeStr = (trange, tunit) => {
  return `startdatetime/${moment(
    moment().subtract(trange, tunit)
  ).format()}/enddatetime/${moment().add(5, HOURS).format()}`
}

export const checkEmptyDB = (db) => {
  const lsDB = JSON.parse(getDataFromLocalStorage(db))

  if (lsDB) {
    const isEmpty = Object.values(lsDB).every((item) => item === '')
    if (isEmpty) return true
  }

  return false
}
