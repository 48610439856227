import { Box, Paper } from '@mui/material'

export const Layout = ({ children, footer, data }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Box
          sx={{ flexGrow: 1, pt: '7.4vh' }}
          component={Paper}
          square={true}
          elevation={0}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {data && children}
          </Box>
        </Box>

        {footer}
      </Box>
    </>
  )
}
