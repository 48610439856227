import React, { useEffect, useState, useContext } from 'react'
import LinearWithValueLabel from '../ProgressBar/Progressbar.component'
import { handleImageError } from '../../util/common.util'
import { Vector, NavigateIcon,Youtube_Logo, PreviousIcon } from '../../asset'
import { TextBlock } from '../common-templates'
import { MediaContext } from '../../util/mediaContext'


const EmblaCarousel = (props) => {
  const { slides: propSlides, emblaRef, content, selectedVideoIndex, emblaSlides } = props
  let { setSlideCount } = useContext(MediaContext)
  const [slides, setSlides] = useState(propSlides)

  useEffect(() => {
  if(selectedVideoIndex === slides.length - 3) {
    setSlides((currentSlides) => {
      if (currentSlides.length === emblaSlides.length) {
        return currentSlides
      }
      const newSlideCount = currentSlides.length + 5 <= emblaSlides.length ? currentSlides.length + 5 : currentSlides.length + (emblaSlides.length - currentSlides.length)
      setSlideCount(() => newSlideCount)
      return Array.from(Array(newSlideCount).keys())
    })
}
},[selectedVideoIndex])

  useEffect(() => {
    return () => {}
  }, [])

  
  return (
    <div className='embla'>
      <div className='embla__viewport' ref={emblaRef}>
        <div className='embla__container'>
          {emblaSlides && emblaSlides.length > 0 && slides.map((item, index) => {
            return (
              <div className='embla__slide embla__class-names' key={index}>
                {content === 'Video' && emblaSlides[item].visited && !((selectedVideoIndex === 0 && index === slides.length - 1) || (selectedVideoIndex === slides.length-1 && index === 0)) &&(
                  <div className='embla__slide_visited'>
                    <span>
                      <img src={Vector} alt='Visited' />
                    </span>
                  </div>
                )}
               {emblaSlides[item].provider === "YouTube" && index === selectedVideoIndex && <img src={Youtube_Logo} alt='youtube video' className='embla__slide_youtube' />}
               {!((selectedVideoIndex === 0 && index === slides.length - 1) || (selectedVideoIndex === slides.length-1 && index === 0)) &&
                <img
                  className='embla__slide__img'
                  src={emblaSlides[item].poster}
                  alt=''
                  onError={() => handleImageError()}
                />}
                {content === 'Video' &&
                  emblaSlides[item].visited && index === selectedVideoIndex && (
                    <LinearWithValueLabel
                      MAX={emblaSlides[item].playerDuration}
                      value={emblaSlides[item].lastVisitedTime}
                    />
                  )}
                {index === selectedVideoIndex && 
                <TextBlock
                  text={emblaSlides[item].title}
                  variant='h4'
                  attributes={{
                    ...(index < selectedVideoIndex
                      ? selectedVideoIndex !== slides.length - 1
                        ? {
                            textAlign: 'right',
                          }
                        : { display: 'none' }
                      : index > selectedVideoIndex
                      ? selectedVideoIndex !== 0
                        ? { textAlign: 'left' }
                        : { display: 'none' }
                      : { textAlign: 'center' }),
                    py: '2vh',
                  }}
                />}
              </div>
            )
          })}
        </div>
        {selectedVideoIndex !== 0 && <img
          className='embla__button embla__button--prev'
          src={PreviousIcon}
          alt='Go to previous slide'
        />}
        <img
          className='embla__button embla__button--next'
          src={NavigateIcon}
          alt='Go to next slide.'
        />
      </div>
    </div>
  )
}

export default EmblaCarousel
