import { Box } from '@mui/material'
import { TextBlock } from '../../common-templates'

const Value = ({ value, unit }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
      }}
    >
      <TextBlock
        text={value}
        variant='h1'
        attributes={{
          color: 'primary.main',
          fontWeight: 'bold',
        }}
      />
      {unit && (
        <TextBlock
          text={unit}
          variant='h2'
          attributes={{
            pl: 2,
            color: 'primary.main',
          }}
        />
      )}
    </Box>
  )
}

export const MainReading = ({ label, value, img, unit }) => {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextBlock
            text={label}
            variant='h4'
            attributes={{
              textTransform: 'capitalize',
              color: 'secondary.dark',
            }}
          />
          <Box sx={{ pl: 3 }}>
            <img
              src={img}
              alt='medical-reading'
              style={{
                height: '6vh',
              }}
            />
          </Box>
        </Box>
        <Value value={value} unit={unit} />
      </Box>
    </>
  )
}
