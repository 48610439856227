import React from "react";
import { useParams } from "react-router-dom";
import IframeVideoPlayer from "./IframeVideo";
import HTMLVideoPlayer from "./HTMLVideo";


export const VideoPlayers = () => {
  const { mediaId } = useParams();

  const player = mediaId.includes("mp4") ? <HTMLVideoPlayer /> :  <IframeVideoPlayer />;
    
    return player
}