import React, { useState, useRef, useEffect } from "react";
import { getFootertime } from "../../util";
import moment from "moment";

const ShowTimer = () => {
    const [timer, setTimer] = useState(moment().format('m'))
    const timerRef = useRef(null);
    useEffect(() => {
        timerRef.current = setInterval(() => {
            setTimer(() => moment().format('m'))
          }, 1000)
    },[])

    useEffect(() => {
       return () => clearInterval(timerRef.current)
    },[])

    return getFootertime()
}

export default ShowTimer;