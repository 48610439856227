import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LinearWithValueLabel from '../ProgressBar/Progressbar.component';
import { MediaContext } from '../../util/mediaContext';
import "../../index.css";
import { Box } from '@mui/material'
import { setLastSeenVideoTime, getDataFromLocalStorage, updateLocalStorage } from '../../util/common.util';
import { VIDEO_PHOTO_ERROR_MESSAGE } from '../../util';
import { transparent_background_mini } from '../../asset';
import { KeyCode } from '../../util/keycode';



export const IframeVideoPlayer = () => {
  const { mediaId: id } = useParams();
  const { player, setVideoPlaying, VideoPlaying, setshowNavigateButton, selectedVideoIndex, setToggleListerner, toggleLister, setIsError, setErrorMessage, deviceId } = useContext(MediaContext);
  const navigate = useNavigate();
  const [hideButtons, setHideButtons] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false)
  let listArray = JSON.parse(getDataFromLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`));
  let currentVideo = listArray[selectedVideoIndex];

  let timeoutRef;

  const handleChange = (event) => {
      event.preventDefault()
      event.stopPropagation()
    if (event.key === "Enter" || event.keyCode === KeyCode.KEY_ENTER) {
      if (VideoPlaying) {
        player.current.pauseVideo();
        setLastSeenVideoTime(localStorage.getItem('Content'), localStorage.getItem("Mode") ,selectedVideoIndex, player, "YouTube");
      } else {
        player.current.playVideo()
      }
    } else if ( event.keyCode === KeyCode.KEY_BACK) {
      setVideoPlaying(() => false)
      setShowProgressBar(() => false)
      player.current.pauseVideo();
      setLastSeenVideoTime(localStorage.getItem("Content"), localStorage.getItem("Mode") ,selectedVideoIndex, player, "YouTube");
      navigate(`/wellness-videos/${localStorage.getItem("Mode")}${localStorage.getItem("Mode") === 'Personal' ? `?deviceuuid=${deviceId}` : ''}`)
    } else {
      setToggleListerner(() => !toggleLister)
    }
    document.removeEventListener('keydown', handleChange)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleChange, false);
  }, [VideoPlaying, toggleLister])


  const onPlayerReady = (e) => {
    if (player.current) {
      currentVideo.playerDuration = player.current.getDuration();
      listArray[selectedVideoIndex] = { ...listArray[selectedVideoIndex], "playerDuration": currentVideo.playerDuration };
      updateLocalStorage(`${localStorage.getItem("Content")+"_"+localStorage.getItem("Mode")}`, listArray)
    }
    if (currentVideo && currentVideo.lastVisitedTime) {
      player.current.seekTo(currentVideo.lastVisitedTime)
    }
    if (document.querySelector('[id^="myvideo"]').requestFullscreen) {
      document.querySelector('[id^="myvideo"]').requestFullscreen()
    }
    player.current.playVideo()

  };

  const onPlayerStateChange = (e) => {
    switch (e.data) {
      case 0:
        setVideoPlaying(() => false)
        setLastSeenVideoTime(localStorage.getItem("Content"), localStorage.getItem("Mode") ,selectedVideoIndex, "", 'YouTube', 'ended')
        break;
      case 1:
        clearTimeout(timeoutRef)
        setVideoPlaying(() => true)
        setShowProgressBar(() => true)
        timeoutRef = setTimeout(() => {
          setHideButtons(true)
        }, 5000)
        player.current.playVideo()
        break;
      case 2:
        setHideButtons(false);
        setVideoPlaying(() => false)
        break;
      default: break;
    }
  }

  const onVideoError = () => {
    setIsError(() => true)
    setErrorMessage(() => localStorage.getItem('Content') === "Video" ? `${VIDEO_PHOTO_ERROR_MESSAGE} Video` : `${VIDEO_PHOTO_ERROR_MESSAGE} Photo`)
  }

  const loadVideo = () => {
    player.current = new window.YT.Player(`youtube-player-${id}`, {
      videoId: id,
      playerVars: {
        controls: 0,
        allow: "fullscreen",
        poster: {transparent_background_mini}
      },
      height: '100%',
      width: '100%',
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
        onError: onVideoError
      },
    });
  }

  useEffect(() => {
    clearTimeout(timeoutRef)
    setshowNavigateButton(false)
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      window.onYouTubeIframeAPIReady = loadVideo;
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      loadVideo();
    }
  }, [])

  useEffect(() => {
    return () => {
      if (window && window.YT) {
        window.YT = null;
      }
      document.removeEventListener('keydown', handleChange)
    }
  }, [])


  return (
    <div id="myvideo">
      <div id={`youtube-player-${id}`} />
      {!hideButtons && currentVideo.playerDuration && showProgressBar && <> <Box sx={{
        position: 'absolute',
        width: '100%',
        bottom: '10%'
      }}>
        <LinearWithValueLabel MAX={currentVideo.playerDuration} value={currentVideo.lastVisitedTime ? currentVideo.lastVisitedTime : 0} />
      </Box>
      </>
      }
    </div>
  )
}

export default React.memo(IframeVideoPlayer);
